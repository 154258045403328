import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
  Image,
  Spinner,
  Alert,
  Badge,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Navigate, useLocation } from 'react-router-dom';
import UserContext from '../../context/usercontext';
import bodyPart from '../../data/bodyParts';
import equipment from '../../data/equipment';
import api from '../../apis/api';
import appApi from '../../apis/appApi';

const EditWorkout = () => {
  const [allExcercise, setAllExcercise] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = JSON.parse(queryParams.get('data'));
  const { user } = useContext(UserContext);
  const [excercise, setExcercise] = useState(data || {});
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState(excercise?.gifUrl || null);
  const [percent, setPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  console.log('excercise', excercise);

  useEffect(() => {
    //add imageUrls to excercise
    setExcercise((prevExercise) => ({
      ...prevExercise,
      gifUrl: imageUrls.urls,
    }));
  }, [imageUrls]);

  function handleMultiSelectInput(e) {
    const options = e.target.options;
    let values = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }

    setExcercise((prevExercise) => ({
      ...prevExercise,
      bodyPart: values,
    }));
    // Now 'values' contains all selected values
    // You can set it to a state variable or use as required
  }

  const handleInput = (e) => {
    const { name, value } = e.target;

    setExcercise((prevExercise) => ({
      ...prevExercise,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submit started');
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const response = await appApi.put(`/workout/${excercise._id}`, excercise);
      setIsLoading(false);
      setShowModal(true);
      console.log(response.status);
      setMessage('Update successful!');

      //reset form
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShowModal(true);
      setMessage('Updated failed: ');
      setIsError(true);
    }
  };

  // const closeSuccessModal = () => {
  //   window.location.href = '/';
  // };

  return (
    <>
      {user?.role === 'admin' ? (
        <Container fluid>
          <Row
            style={{
              width: '50%',
              marginBottom: '2rem',
              margin: 'auto',
              borderBottom: '1px solid #ccc',
              //background: 'grey',
              alignItems: 'center',
            }}>
            {/* center h1 */}
            <Col>
              <h1>{excercise ? ` ${excercise.name}` : ' Edit excercise'}</h1>
            </Col>
            <Col>
              <Image
                src={imageUrls}
                style={{ width: '100px', height: '100px' }}
                roundedCircle
              />
            </Col>
          </Row>

          <Row>
            <Col
              fluid
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Form
                onSubmit={(e) => {
                  // setIsDisabled(true);
                  e.preventDefault();
                  handleSubmit(e);
                }}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice Name</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.name}
                        required
                        type="text"
                        placeholder="name of excersice"
                        name="name"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice sets</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.sets}
                        type="number"
                        placeholder="number of sets"
                        name="sets"
                        onChange={handleInput}
                        disabled={
                          excercise.type === 'warmup' ||
                          excercise.type === 'cooldown'
                        }
                      />
                    </Form.Group>
                  </Col>
                  {/* {excercise.type !== 'cooldown' &&
                    excercise.type !== 'warmup' && (
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Excersice order</Form.Label>
                          <Form.Control
                            defaultValue={excercise?.order}
                            type="number"
                            placeholder="order"
                            name="order"
                            onChange={handleInput}
                          />
                        </Form.Group>
                      </Col>
                    )} */}
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Input Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.inputType}
                        name="inputType"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select input type</option>
                        <option value="timer">Timer</option>
                        <option value="rep">Reps</option>
                        <option value="weight">Weight</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> gifUrl</Form.Label>
                      <Form.Control
                        defaultValue={state?.data?.gifUrl}
                        required
                        type="text"
                        placeholder="gifUrl"
                        name="gifUrl"
                        onChange={handleInput}
                      />
                    </Form.Group> */}
                    {/* <Form.Group>
                      <Form.Label>Image (gif)</Form.Label>
                      <Form.Control
                        //defaultValue={excercise?.gifUrl}
                        //  required={excercise?.active ? true : false}
                        type="file"
                        placeholder="Image"
                        name="gifUrl"
                        onChange={(e) => handleUploadImage(e)}
                      />
                    </Form.Group>
                    <Spinner
                      animation="grow"
                      variant="danger"
                      style={{ display: loading ? 'block' : 'none' }}
                    /> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.type}
                        name="type"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select type</option>
                        <option value="warmup">Warm up</option>
                        <option value="normal">Normal Excersice</option>
                        <option value="cooldown">Cool down</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Muscle Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.muscleType}
                        name="muscleType"
                        as="select"
                        onChange={handleInput}>
                        <option value="">Select muscle Type</option>
                        <option value="huge">huge</option>
                        <option value="large">large</option>
                        <option value="long">long</option>
                        <option value="short">short</option>
                        <option value="small">small</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>equipment</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.equipment}
                        name="equipment"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Equipment</option>
                        {equipment.map((equipment) => (
                          <option value={equipment}>{equipment}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>bodyPart</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.bodyPart}
                        name="bodyPart"
                        as="select"
                        required
                        multiple
                        onChange={handleMultiSelectInput}>
                        <option value="">Select Body Part</option>
                        {bodyPart.map((bodyPart) => (
                          <option value={bodyPart}>{bodyPart}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Main Target</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.mainTarget}
                        name="mainTarget"
                        as="select"
                        onChange={handleInput}>
                        <option value="">Select Main Target</option>
                        <option value="Chest">Chest</option>
                        <option value="Triceps">Triceps</option>
                        <option value="Leg">Leg</option>
                        <option value="Back">back</option>
                        <option value="Glutes">Glutes</option>
                        <option value="Shoulder">shoulders</option>
                        <option value="Biseps">Biseps</option>
                        <option value="Abs">Abs</option>
                        <option value="Waist">Waist</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Other Target</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.otherTarget}
                        name="otherTarget"
                        as="select"
                        onChange={handleInput}>
                        <option value="">Select other Target</option>
                        <option value="Chest">Chest</option>
                        <option value="Triceps">Triceps</option>
                        <option value="Leg">Leg</option>
                        <option value="Back">back</option>
                        <option value="Glutes">Glutes</option>
                        <option value="Shoulder">shoulders</option>
                        <option value="Biseps">Biseps</option>
                        <option value="Abs">Abs</option>
                        <option value="Waist">Waist</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  {/* <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.category}
                        name="category"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Category</option>
                        {workOutCategories.map((category) => (
                          <option value={category.name}>{category.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  {excercise?.type !== 'warmup' &&
                    excercise?.type !== 'cooldown' && (
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Location</Form.Label>
                          <Form.Select
                            defaultValue={excercise?.loc}
                            name="loc"
                            as="select"
                            required
                            onChange={handleInput}>
                            <option value="">Select Location</option>
                            <option value="Home">Home</option>
                            <option value="Gym">Gym</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )}
                </Row>
                {/* <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice Level</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.level}
                        name="level"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Level</option>
                        <option value="beginner">beginner</option>
                        <option value="moderate">moderate</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <Form.Group></Form.Group>
                    <Form.Group></Form.Group>
                  </Col>
                </Row>

                <Row>
                  {/* <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.active}
                        name="active"
                        onChange={handleInput}>
                        <option>Select Status</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  {/* <Col>
                    <Form.Group>
                      <Form.Label>Video </Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="video"
                        name="video"
                        onChange={(e) => handleUploadImage(e)}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
                {/* <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.description}
                        as="textarea"
                        placeholder="details about the excersice"
                        name="description"
                        onChange={handleInput}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description (Persian)</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.faDescription}
                        as="textarea"
                        placeholder="details about the excersice"
                        name="faDescription"
                        onChange={handleInput}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>instructor</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.instructor}
                        as="textarea"
                        placeholder="instructions about the excersice"
                        name="instructor"
                        onChange={handleInput}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {' '}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>instructor (persian)</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.faInstructor}
                        as="textarea"
                        placeholder="instructions about the excersice"
                        name="faInstructor"
                        onChange={handleInput}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="m-3" controlId="formBasicEmail">
                  <Button
                    style={{ width: '100%' }}
                    variant="primary"
                    size="lg"
                    type="submit">
                    {!isLoading ? (
                      'Submit'
                    ) : (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Form.Group>
                {/* show alert or success message here */}
                {showModal && (
                  <Alert variant="success">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{message}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button
                        //
                        onClick={() => setShowModal(false)}
                        variant="outline-success">
                        Close
                      </Button>
                    </div>
                  </Alert>
                )}
                {isError && (
                  <Alert variant="danger">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{message}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={() => setIsError(false)}
                        variant="outline-danger">
                        Close
                      </Button>
                    </div>
                  </Alert>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default EditWorkout;
