import React, { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import uploadImage from '../../apis/uploadImage';
import { TagsInput } from 'react-tag-input-component';
import appApi from '../../apis/appApi';

function InserForm({ setPlanName, planName }) {
  const [target, setTarget] = useState('');
  const [level, setLevel] = useState('');
  const [duration, setDuration] = useState('');
  const [DaysPerWeek, setDaysPerWeek] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [totalSessions, setTotalSessions] = useState(0);
  const [imageUrls, setImageUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(['Lose Fat', 'Advanced']);

  const handleImage = async (e) => {
    const file = e.target.files[0];

    const onProgress = (percent) => {
      console.log(`${percent}% uploaded`);
    };

    try {
      setLoading(true);
      const result = await uploadImage(file, onProgress);
      console.log(result.imageUrls);
      setImageUrls(result.imageUrls);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const userId = '64e26209c0bab3ae4f0825c';
  const handleCreatePlan = async () => {
    try {
      appApi
        .post('/newplan/prebuild', {
          name: planName,
          userId: userId,
          goal: target,
          level: level,
          duration: duration,
          DaysPerWeek: DaysPerWeek,
          image: imageUrls.urls,
          description: description,
          location: location,
          totalSessions: totalSessions,
          tags: tags,
          creator: 'Fitlinez AI',
        })
        .then((res) => {
          console.log(res.data);
          //refresh the page
          window.location.reload();
          // setShowTable(true);

          localStorage.setItem('planId', res.data.data._id);
          localStorage.setItem('plnaName', res.data.data.name);
          localStorage.setItem('planTarget', res.data.data.target);
          localStorage.setItem('planLevel', res.data.data.level);
          localStorage.setItem('planDuration', res.data.data.duration);
          localStorage.setItem('planDaysPerWeek', res.data.data.DaysPerWeek);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <Image
            cla
            src={
              imageUrls.urls
                ? imageUrls.urls
                : 'https://via.placeholder.com/150'
            }
            thumbnail
          />
        </Col>
        <Col xs={12} md={6}>
          <Form>
            <Form.Group className="mb-3 d-flex justify-content-between gap-3">
              <Form.Control
                type="text"
                placeholder="Enter Plan Name"
                onChange={(e) => setPlanName(e.target.value)}
              />
              <Form.Control
                type="file"
                placeholder="image"
                onChange={handleImage}
              />
            </Form.Group>
            <Form.Group className=" mb-3 d-flex justify-content-between gap-3">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setTarget(e.target.value);
                }}>
                <option value="">Select Target </option>

                <option value="Lose Fat">Lose Fat</option>
                <option value="Gain Muscle">Gain muscle</option>
                <option value="Maintain Weight">Maintain Weight</option>
                <option value="Stay Fit">Stay Fit</option>
              </Form.Select>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setLevel(e.target.value);
                }}>
                <option value="">Select Level</option>

                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </Form.Select>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}>
                <option value="">Select Location </option>

                <option value="Home">Home</option>
                <option value="Gym">Gym</option>
                <option value="both">home & Gym</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <TagsInput
                value={tags}
                onChange={setTags}
                name="tags"
                placeHolder="enter tags"
              />
            </Form.Group>
            <Form.Group className=" mb-3 d-flex justify-content-between gap-3">
              <Form.Control
                type="number"
                placeholder="duration"
                onChange={(e) => setDuration(e.target.value)}
              />
              <Form.Control
                type="number"
                placeholder="DaysPerWeek"
                onChange={(e) => setDaysPerWeek(e.target.value)}
              />
              <Form.Control
                type="number"
                placeholder="total Sessions"
                onChange={(e) => setTotalSessions(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3"></Form.Group>
            <Form.Group className="mb-3">
              <Button
                variant="success"
                onClick={handleCreatePlan}
                disabled={loading}>
                {loading ? 'Uploading...' : 'Add new Plan'}
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default InserForm;
