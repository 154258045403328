import React from 'react';

const FilePreview = ({ fileUrl, index }) => {
  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    return extension;
  };

  const renderPreview = (url) => {
    const fileType = getFileType(url);

    // Handle image formats (png, jpg, gif, webp, etc.)
    const imageFormats = ['png', 'jpg', 'jpeg', 'gif', 'webp'];
    if (imageFormats.includes(fileType)) {
      return (
        <div>
          <img
            src={url}
            alt={`Image ${index + 1}`}
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            className="عکس"
          />
        </div>
      );
    }

    // Handle PDF format
    if (fileType === 'pdf') {
      return (
        <div className="w-32 h-32 mt-2 flex items-center justify-center">
          <div className="relative w-32 h-32">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              // className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 transition-opacity duration-300"
              aria-label={`Open PDF ${index + 1} in new tab`}>
              Open in new tab
            </a>
            <iframe
              className="rounded-lg w-full h-full"
              src={url}
              title={`PDF Preview ${index + 1}`}
              frameBorder="0"></iframe>
          </div>
        </div>
      );
    }

    // Handle other file formats (display a link)
    return (
      <div className="w-32 h-32 mt-2 flex items-center justify-center">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          // className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 transition-opacity duration-300"
          aria-label={`Open PDF ${index + 1} in new tab`}>
          Open in new tab
        </a>
      </div>
    );
  };

  return (
    <div className="flex justify-center mb-3">
      {fileUrl && renderPreview(fileUrl)}
    </div>
  );
};

export default FilePreview;
