import { useEffect, useState } from 'react';
import { Badge, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import api from '../../apis/api';
import CommentsList from './comments/commentsList';
import { getLastReports } from '../../apis/getData';

import '../../index.css';
const UserMainPage = () => {
  const [show, setShow] = useState(true);
  const [users, setUsers] = useState([]);
  const [weeklyReportArray, setWeeklyReportArray] = useState([]);
  const [monthlyReportArray, setMonthlyReportArray] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState('idle');
  const [lastReports, setLastReports] = useState([]);

  const handleGetLastReport = () => {
    getLastReports().then((res) => {
      setLastReports(res);
    });
  };

  useEffect(() => {
    handleGetLastReport();
  }, []);

  const today = new Date();

  const twoDaysAgo = new Date(today);
  twoDaysAgo.setDate(today.getDate() - 1); // Use -2 to get the date two days ago
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getReports = () => {
    api.get('/report/reports').then((res) => {
      // Filter the data to get items with 'date' within the last two days ago
      const filteredData = res.data.filter((item) => {
        const itemDate = new Date(item.date); // Assuming 'item.date' is a valid date string
        return itemDate >= twoDaysAgo && itemDate <= today;
      });

      setWeeklyReportArray(filteredData);
    });
  };

  const getUsersSizeReport = () => {
    api.get('/report/sizereports').then((res) => {
      setMonthlyReportArray(res.data);
    });
  };

  const weeklyReportArrayUserId = weeklyReportArray.map((item) => {
    if (item.date !== twoDaysAgo) return item.userId;
  });

  const filteredUsers = users.filter(
    (user) =>
      user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getUserList = () => {
    setStatus('loading');
    api
      .get(`/getUsers`)
      .then((res) => {
        setStatus('success');
        setUsers(res.data);
      })
      .catch((err) => {
        setStatus('error');
        alert(err.message);
      });
  };
  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getReports();
    getUsersSizeReport();
  }, []);

  const filteredData = filteredUsers.filter((item) =>
    item.hasOwnProperty('userWorkOutData')
  );

  const filteredByReport = filteredData.filter((user) =>
    weeklyReportArrayUserId.includes(user._id)
  );
  console.log(filteredByReport.length);
  const listOfFilters = [
    { name: 'گزارش هفتگی', value: lastReports, alias: 'weekly' },
    { name: 'تمام کاربران', value: users, alias: 'all' },
    {
      name: 'Shape up pro 6',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up pro 6')
      ),
      alias: 'Shape up pro 6',
    },
    {
      name: 'Shape up 7',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up 7')
      ),
      alias: 'Shape up 7',
    },
    {
      name: 'Shape up 6',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up 6')
      ),
      alias: 'Shape up 6',
    },
    {
      name: 'Shape up pro 5',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up pro 5')
      ),
      alias: 'shapeuppro5',
    },
    {
      name: 'Shape up Academy',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up Academy')
      ),
      alias: 'shapeupacademy',
    },
    {
      name: 'Shape up pro 4',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up pro 4')
      ),
      alias: 'shapeuppro4',
    },

    {
      name: 'Shape up 5',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up 5')
      ),
      alias: 'Shape up 5',
    },

    {
      name: 'Shape up 4',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up 4')
      ),
      alias: 'Shape up 4',
    },
    {
      name: 'Shape up 3',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up 3')
      ),
      alias: 'shapeup3',
    },
    {
      name: 'Shape up pro 3',
      value: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up pro 3')
      ),
      length: users.filter((user) =>
        user.userProduct.some((product) => product.name === 'Shape up pro 3')
      ).length,
      alias: 'shapeuppro3',
    },
  ];
  return (
    <Container style={{ fontFamily: 'Vazirmatn' }} className="mt-5 w-1/2">
      <Row>
        <Col lg={3}>
          <h2 className="text-center">لیست کاربران</h2>

          <br />
          <ListGroup>
            {listOfFilters.sort().map((item, index) => {
              return (
                <ListGroup.Item
                  style={{
                    fontFamily: 'Vazirmatn',
                    direction: 'rtl',
                    textAlign: 'right',
                  }}>
                  <Badge bg="primary" className="ms-2">
                    {item?.value.length}
                  </Badge>
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: 'black',

                      textAlign: 'left',
                    }}
                    to={`/users/${item.alias}`}
                    state={{ value: item.value, name: item.name }}>
                    {item.name}
                  </Link>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
        <Col lg={9}>
          <h2 className="text-center">آخرین کامنتها </h2>
          <CommentsList />
        </Col>
      </Row>
    </Container>
  );
};

export default UserMainPage;
