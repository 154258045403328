import { useContext, useState } from 'react';
import {
  Col,
  Button,
  Row,
  Container,
  Card,
  Form,
  Modal,
} from 'react-bootstrap';
import UserContext from '../context/usercontext';
// import Logo from '../img/logo.png';
// import UserContext from '../context/usercontext';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);
  const [response, setResponse] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (email === 'azarjoon' && password === 'azarvahid') ||
      (email === 'zohreh' && password === '123456')
    ) {
      localStorage.setItem(
        'credentials',
        JSON.stringify({ role: 'admin', name: email })
      );
      setUser(JSON.parse(localStorage.getItem('credentials')));

      window.open('/', '_self');
    } else {
      setResponse('Username or password is incorrect');
    }
  };

  return (
    <div className="font-face-iran-sans">
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          {response && (
            <Modal
              show={response}
              onHide={() => {
                setResponse(null);
              }}
              centered
              size="sm"
              className="text-center font-face-iran-sans"
              animation={false}
              aria-labelledby="contained-modal-title-vcenter">
              <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
              </Modal.Header>
              <Modal.Body>{response}</Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    setResponse(null);
                  }}
                  variant="secondary">
                  close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <Col md={8} lg={6} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  {/* <img
                    src={Logo}
                    alt="logo"
                    width="100"
                    height="100"
                    className="d-inline-block align-top mx-auto d-block"
                  /> */}
                  <div className="mb-3">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          User name
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          placeholder="Enter email"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword">
                        <Form.Label>Password </Form.Label>
                        <Form.Control
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Group>
                      {/* <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox">
                        <p className="small">
                          <a className="text-primary" href="#!">
                            فراموشی رمز عبور
                          </a>
                        </p>
                      </Form.Group> */}
                      <div className="d-grid">
                        <Button
                          onClick={handleSubmit}
                          variant="primary"
                          type="submit">
                          Login
                        </Button>
                      </div>
                    </Form>

                    {/* <div className="mt-3">
                      <p className="mb-0  text-center">
                        Don't have an account?{' '}
                        <a href="{''}" className="text-primary fw-bold">
                          Sign Up
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
