import { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import bodyPart from '../../data/bodyParts';
import { AllList } from './allList';
import SelectedList from './SelectedList';
import InserForm from './InserForm';

import appApi from '../../apis/appApi';

const GenerateWorkOut = () => {
  const [planName, setPlanName] = useState('');
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [listId, setListId] = useState([]);
  const [daynName, setDayName] = useState('');

  const [showTable, setShowTable] = useState(false);
  const [planId, setPlanId] = useState('');
  const [title, setTitle] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBodyPart, setSelectedBodyPart] = useState('all');
  const [type, setType] = useState('normal');

  const [location, setLocation] = useState('Gym');
  //generate days of the week
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  useEffect(() => {
    //get all excercise
    const getAllExcercise = async () => {
      try {
        const response = await appApi.get('/newPlan');

        const filteredData = response.data.filter(
          (item) => item.active === false || item.active === true
        );
        setData(filteredData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllExcercise();
  }, []);
  useEffect(() => {
    const planId = localStorage.getItem(`planId`);
    if (planId) {
      setShowTable(true);
      setPlanId(planId);
    }
  }, []);

  const categorizedData = data.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, item]);
      setListId([...listId, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem._id !== item._id)
      );
    }
  };

  const handlechangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleCreatePlan = () => {
    //merge localstorage that start with plan
    //const plan = localStorage.getItem(`plan${today}`);
    // You can perform any desired action with the selectedItems array here
  };

  const handleAddDays = () => {
    try {
      localStorage.setItem(
        `plan${[daynName]}`,
        JSON.stringify({ day: daynName, data: selectedItems })
      );
      appApi
        .put(`/newplan/prebuild/${planId}`, {
          name: planName,
          day: daynName,
          title: title,
          data: listId,
        })
        .then((res) => {
          console.log('res', res);
          alert('added');
          window.location.reload();
        });
      // alert('added');
      //refresh the page
      //window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleFilterChange = (newFilter) => {
    setSearchQuery(newFilter);
  };
  const filteredExercise = data
    .filter((item) =>
      item.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((item) => item.loc === location)

    .filter(
      (item) =>
        selectedBodyPart === 'all' ||
        (item.bodyPart &&
          typeof item.bodyPart === 'string' &&
          item.bodyPart.toLowerCase() === selectedBodyPart.toLowerCase())
    )
    .filter((item) => type === null || type === 'normal' || item.type === type);

  console.log(filteredExercise.length);

  const handleBodyPartChange = (event) => {
    setSelectedBodyPart(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    const home = selectedItems.filter((item) => item.loc === 'Home').length;
    const gym = selectedItems.filter((item) => item.loc === 'Gym').length;
    const both = selectedItems.filter((item) => item.loc === 'Both').length;
    const warmup = selectedItems.filter(
      (item) => item.type === 'warmup'
    ).length;
    const coolDown = selectedItems.filter(
      (item) => item.type === 'cooldown'
    ).length;

    // setHomeSelected(home);
    // setGymSelected(gym);
    // setBothSelected(both);
    // setWarmupSelected(warmup);
    // setCoolDownSelected(coolDown);
  }, [selectedItems]);

  //input post to db and return value to user
  return (
    <Container className="mt-5">
      {!showTable ? (
        <InserForm planName={planName} setPlanName={setPlanName} />
      ) : (
        <Row>
          <Row
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              bottom: '100',
              width: '100%',
              zIndex: '999',
              backgroundColor: 'white',
            }}
            className="bg-body-tertiary">
            <Col>
              <Form.Label htmlFor="inputPassword5">Enter Day Name</Form.Label>
              <Form.Select
                name="location"
                onChange={(e) => setDayName(e.target.value)}>
                <option value="all">Select day</option>
                {daysOfWeek.map((part, index) => (
                  <option key={index} value={part}>
                    {part}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label htmlFor="inputPassword5">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Full Body or Chest Day"
                id="dayName"
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                style={{
                  marginTop: '2rem',
                  marginLeft: '2rem',
                  marginBottom: '2rem',
                }}
                variant="primary"
                on
                onClick={handleAddDays}>
                Add Selected to Days
              </Button>
            </Col>
          </Row>

          <Row
            style={{
              // position: 'fixed',
              marginTop: '4rem',
              left: '0',
              width: '100%',

              backgroundColor: 'white',
            }}
            className="mb-11">
            <Col>
              <Form.Control
                type="text"
                placeholder="Search by name"
                onChange={(e) => handleFilterChange(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Select name="bodyPart" onChange={handleBodyPartChange}>
                <option value="all">Select Body Part</option>
                {bodyPart.map((part, index) => (
                  <option key={index} value={part}>
                    {part}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Select name="location" onChange={handlechangeLocation}>
                <option value="Home">Home</option>
                <option value="Gym">Gym</option>
                <option value="Both">Both</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select name="type" onChange={handleTypeChange}>
                <option value="all">Filter Type</option>
                <option value="normal">Normal</option>
                <option value="cooldown">cooldown</option>
                <option value="warmup">warmup</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <AllList
                filteredExcercise={data}
                handleCheckboxChange={handleCheckboxChange}
                selectedItems={selectedItems}
              />
            </Col>
            <Col sm={6}>
              <SelectedList selectedItems={selectedItems} />
            </Col>
          </Row>
          {/* {workOutCategories?.map((item) => (
            <Card style={{ width: '18rem' }} className="mb-2">
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Text>{item.target}</Card.Text>
                <Image
                  src={item.gifUrl}
                  style={{ width: '100px', height: '100px' }}
                />
                <Card.Text>{item.category}</Card.Text>
                <Card.Text>{item.location}</Card.Text>
                <Card.Text>{item.bodyPart}</Card.Text>
                <Card.Text>{item.equipment}</Card.Text>
                <Card.Text>{item.level}</Card.Text>
              </Card.Body>
            </Card>
          ))} */}
        </Row>
      )}
    </Container>
  );
};

export default GenerateWorkOut;
