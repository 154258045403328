import React, { useState } from 'react';
import appApi from '../../apis/appApi';
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  ProgressBar,
  Row,
  Spinner,
} from 'react-bootstrap';

import { sendInitialReq } from '../../apis/foodApi';

function Tools() {
  const [video, setVideo] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('idle');
  const [percent, setPercent] = useState(1);
  const [score, setScore] = useState(8);
  const [error, setError] = useState('');
  const [userInput, setUserInput] = useState('');
  const [foodData, setFoodData] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  console.log('videoUrl', videoUrl);
  const handleFileChange = (e) => setVideo(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('video', video);

    try {
      const response = await fetch('http://localhost:8080/videoai/upload', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      console.log('data:', data);
      setResult(data.result);
      setVideoUrl(data.publicUrl);
      setScore(data.result.score);
    } catch (error) {
      setError(error);
      console.error('Error uploading video:', error.error);
    } finally {
      setLoading(false);
    }
  };

  const progress = () => {};

  //   const uploadFiles = async (selectedFiles) => {
  //     const formData = new FormData();

  //     selectedFiles.forEach((file) => {
  //       formData.append('images', file); // 'images' should match the key the backend expects
  //     });

  //     try {
  //       setStatus('uploading');
  //       console.log('upload files');
  //       const response = await appApi.post(
  //         '/imageUploader/multiple', // Update with your Express server endpoint
  //         formData,
  //         {
  //           headers: {
  //             'Content-Type': 'multipart/form-data',
  //           },
  //           onUploadProgress: (progressEvent) => {
  //             const { loaded, total } = progressEvent;
  //             const percentage = Math.floor((loaded * 100) / total);
  //             setPercent(percentage);
  //           },
  //         }
  //       );
  //       setImageUrls(response.data.urls); // Assuming response.data.urls is an array of image URLs
  //       setButtonDisabled(false);
  //       setPercent(100);
  //       setStatus('images_uploaded');
  //     } catch (error) {
  //       console.log(error.response.data);
  //       setMessage(error.response.data);
  //       console.error('Error uploading files', error);
  //       setStatus('error');
  //       Bugsnag.notify('Error uploading images', error);
  //     }
  //   };

  const handleFoodAPi = async () => {
    setLoading(true);
    try {
      const res = await sendInitialReq(
        userInput,
        'test',
        'Breakfast',
        setStatus
      );
      console.log('res', res);
      setFoodData(res.data.currentFood);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching food data:', error);
      setError(error);
      setLoading(false);
    }
  };
  return (
    <Container className="App mt-4">
      <Col>
        <h1>AI Exercise Eveluator</h1>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              {/* <Form.Control placeholder="First name" /> */}

              <input
                onChange={handleFileChange}
                accept="video/*"
                id="input-b1"
                name="input-b1"
                type="file"
                class="file"
                data-browse-on-zone-click="true"
              />

              <Button type="submit" variant="primary">
                Analyze Video
              </Button>
            </Col>
            <Col>
              {videoUrl ? (
                <video width="320" height="240" controls>
                  <source src={videoUrl} type="video/mp4" />
                </video>
              ) : (
                <div className="text-center h-100 w-100 border rounded">
                  No video uploaded
                </div>
              )}
            </Col>
          </Row>
        </Form>

        {loading && (
          <p>
            <Spinner animation="grow" />
          </p>
        )}
        {result && (
          <Row className="mt-5 mx-5 mb-5">
            <h2>Evaluation Result</h2>
            <Col>
              <p className="w-1/2">
                <ProgressBar
                  variant={
                    score < 5 ? 'danger' : score < 8 ? 'warning' : 'success'
                  }
                  label={`${score}/10`}
                  animated={true}
                  striped={true}
                  now={score}
                  max={10}
                />
              </p>
              <p>Trainee's Gender: {result.gender}</p>
              <p>Exercise Name:{result.exerciseName}</p>
            </Col>
            <Col>
              <ListGroup>
                <h4>Suggestions for improvement </h4>
                {result.suggestions.map((suggestion, index) => (
                  <ListGroup.Item key={index}>{suggestion}</ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}
      </Col>
      <Col>
        {error && <p>{'error'}</p>}
        <hr />
        <Row className="mt-5">
          <h3>AI Calorie Counter</h3>
          <Col>
            <Form.Group
              className="mt-5 "
              controlId="exampleForm.ControlTextarea1">
              <Form.Control
                placeholder="Enter your food items"
                onChange={(e) => setUserInput(e.target.value)}
                as="textarea"
                rows={3}
              />
              <Button
                className="mt-3"
                onClick={() => {
                  handleFoodAPi();
                }}
                variant="primary">
                Submit
              </Button>
            </Form.Group>
          </Col>
          <Col>
            {foodData && (
              <Row className="mt-5">
                <Col>
                  <ListGroup>
                    <h3>Nutritional Information</h3>
                    {foodData.foodItems.map((item, index) => (
                      <ListGroup.Item key={index}>
                        <h5>{item.food_item}</h5>
                        <p>Serving Size: {item.serving_size}</p>
                        <p>
                          Calories: {item.calories.amount} {item.calories.unit}
                        </p>
                        <p>
                          Total Fat: {item.total_fat.amount}{' '}
                          {item.total_fat.unit}
                        </p>
                        <p>
                          Saturated Fat: {item.saturated_fat.amount}{' '}
                          {item.saturated_fat.unit}
                        </p>
                        <p>
                          Cholesterol: {item.cholesterol.amount}{' '}
                          {item.cholesterol.unit}
                        </p>
                        <p>
                          Sodium: {item.sodium.amount} {item.sodium.unit}
                        </p>
                        <p>
                          Total Carbohydrates: {item.total_carbohydrates.amount}{' '}
                          {item.total_carbohydrates.unit}
                        </p>
                        <p>
                          Dietary Fiber: {item.dietary_fiber.amount}{' '}
                          {item.dietary_fiber.unit}
                        </p>
                        <p>
                          Sugars: {item.sugars.amount} {item.sugars.unit}
                        </p>
                        <p>
                          Protein: {item.protein.amount} {item.protein.unit}
                        </p>
                        <h6>Vitamins and Minerals:</h6>
                        <ul>
                          <li>
                            Vitamin A:{' '}
                            {item.vitamins_and_minerals.vitamin_a?.amount}{' '}
                            {item.vitamins_and_minerals.vitamin_a?.unit}
                          </li>
                          <li>
                            Vitamin C:{' '}
                            {item.vitamins_and_minerals.vitamin_c?.amount}{' '}
                            {item.vitamins_and_minerals.vitamin_c?.unit}
                          </li>
                          <li>
                            Calcium:{' '}
                            {item.vitamins_and_minerals.calcium?.amount}{' '}
                            {item.vitamins_and_minerals.calcium?.unit}
                          </li>
                          <li>
                            Iron: {item.vitamins_and_minerals.iron?.amount}{' '}
                            {item.vitamins_and_minerals.iron?.unit}
                          </li>
                        </ul>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default Tools;
