import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
  Image,
  Spinner,
  Alert,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Navigate, useLocation } from 'react-router-dom';

import UserContext from '../../context/usercontext';
import api from '../../apis/api';
import appApi from '../../apis/appApi';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const AddNew = () => {
  const [allExcercise, setAllExcercise] = useState([]);
  const location = useLocation();
  const state = location.state;
  const { user } = useContext(UserContext);
  const [excercise, setExcercise] = useState(state?.data || {});
  console.log(excercise);
  const [isDisabled, setIsDisabled] = useState(false);

  const [imageUrls, setImageUrls] = useState(excercise?.newImageUrl || []);

  const [percent, setPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setExcercise((prevExercise) => ({
      ...prevExercise,
      [name]: value,
    }));
  };

  const workOutCategories = [
    {
      id: 1,
      name: 'Chest & Triceps',
    },
    {
      id: 2,
      name: 'Back & Abs',
    },
    {
      id: 3,
      name: 'Legs & Calves',
    },
    {
      id: 4,
      name: 'Shoulders & Biceps',
    },
  ];

  const parts = [
    'Chest',
    'Upper Back',
    'Lower Back',
    'Shoulders',
    'Biceps',
    'Triceps',
    'Forearms',
    'Abdominals',
    'Obliques',
    'Quadriceps',
    'Hamstrings',
    'Glutes',
    'Calves',
    'Hip Adductors',
    'Hip Abductors',
  ];

  const equipments = [
    'None',
    'Dumbbells',
    'Barbells',
    'Weight Plates',
    'Kettlebells',
    'Resistance Bands',
    'Pull-Up Bar',
    'Bench',
    'Squat Rack',
    'Cable Machine',
    'Treadmill',
    'Elliptical Machine',
    'Stationary Bike',
    'Rowing Machine',
    'Jump Rope',
    'Medicine Ball',
    'Yoga Mat',
    'Foam Roller',
    'TRX Suspension Trainer',
    'Battle Ropes',
    'Gymnastic Rings',
    'Sandbags',
    'Punching Bag',
    'Stability Ball',
    'Step Platform',
    'Box for Box Jumps',
    'Parallettes',
    'Ab Wheel',
    'Weightlifting Belt',
    'Weighted Vest',
    'Agility Ladder',
    'Cones',
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setIsDisabled(true);

    try {
      const response = await appApi.post(`/workout/`, excercise);
      setIsLoading(false);
      setShowModal(true);
      console.log(response.status);
      setMessage('Update successful!');

      //reset form
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      setShowModal(true);
      setMessage('Updated failed: ');
      setIsError(true);
    }
  };

  // const closeSuccessModal = () => {
  //   window.location.href = '/';
  // };

  useEffect(() => {
    //get all excercise
    const getAllExcercise = async () => {
      try {
        const response = await appApi.get('/workout/newPlan');
        //filter by active and bodypart
        const notactiveExcercise = response.data.filter(
          (item) =>
            item.active === false && item.bodyPart === excercise.bodyPart
        );

        setAllExcercise(notactiveExcercise);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllExcercise();
  }, []);

  return (
    <>
      {user?.role === 'admin' ? (
        <Container col={2} style={{ marginTop: '5rem' }}>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            {/* center h1 */}
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <h1>
                {excercise ? `Add ${excercise.name}` : 'Add new excercise'}
              </h1>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Form
                onSubmit={(e) => {
                  // setIsDisabled(true);
                  e.preventDefault();
                  handleSubmit(e);
                }}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice Name</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.name}
                        required
                        type="text"
                        placeholder="name of excersice"
                        name="name"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice Rest Time</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.counter}
                        type="number"
                        placeholder="counter in seconds"
                        name="counter"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Input Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.inputType}
                        name="inputType"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Body Part</option>
                        <option value="timer">Timer</option>
                        <option value="rep">Reps</option>
                        <option value="weight">Weight</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.type}
                        name="type"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Body Part</option>
                        <option value="warmup">Warm up</option>
                        <option value="normal">Normal Excersice</option>
                        <option value="cooldown">Cool down</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>equipment</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.equipment}
                        name="equipment"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Equipment</option>
                        {equipments.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>bodyPart</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.bodyPart}
                        name="bodyPart"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Body Part</option>
                        {parts.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Main Target</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.mainTarget}
                        name="mainTarget"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Main Target</option>
                        {parts.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>other Target</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.otherTarget}
                        name="otherTarget"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select other Target</option>
                        {parts.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.category}
                        name="category"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Category</option>
                        {workOutCategories.map((category) => (
                          <option value={category.name}>{category.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.loc}
                        name="loc"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Location</option>
                        <option value="Home">Home</option>
                        <option value="Gym">Gym</option>
                        <option value="Both">Both</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice Level</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.level}
                        name="level"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Level</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> gifUrl</Form.Label>
                      <Form.Control
                        defaultValue={state?.data?.gifUrl}
                        required
                        type="text"
                        placeholder="gifUrl"
                        name="gifUrl"
                        onChange={handleInput}
                      />
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Label>Image (gif)</Form.Label>
                      <Form.Control
                        //defaultValue={excercise?.gifUrl}
                        //  required={excercise?.active ? true : false}
                        type="file"
                        placeholder="Image"
                        name="gifUrl"
                        onChange={(e) => handleFileChange(e, 0)}
                      />
                    </Form.Group> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      {/* remove image buttton */}
                      {imageUrls[0] && (
                        <svg
                          onClick={() => {
                            setImageUrls((prev) => {
                              const newState = [...prev];
                              newState.splice(0, 1);
                              return newState;
                            });
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          class="bi bi-x-circle"
                          viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      )}
                    </Form.Group>
                    <Form.Group>
                      {
                        imageUrls[0] ? (
                          <Image
                            src={imageUrls[0]}
                            alt="image"
                            style={{
                              width: '200px',
                              height: '200px',
                              objectFit: 'contain',
                              // center everythin
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        ) : null
                        // <Image
                        //   src={excercise?.gifUrl}
                        //   alt="image"
                        //   style={{
                        //     width: '200px',
                        //     height: '200px',
                        //     objectFit: 'contain',
                        //     // center everythin
                        //     display: 'flex',
                        //     alignItems: 'center',
                        //     justifyContent: 'center',
                        //   }}
                        // />
                      }
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.active}
                        name="active"
                        onChange={handleInput}>
                        <option>Select Status</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Video (temporary disabled )</Form.Label>
                      <Form.Control
                        disabled
                        type="file"
                        placeholder="video"
                        name="video"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    defaultValue={excercise?.description}
                    as="textarea"
                    placeholder="details about the excersice"
                    name="description"
                    onChange={handleInput}
                    style={{ height: '100px' }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>instructor</Form.Label>
                  <Form.Control
                    defaultValue={excercise?.instructor}
                    as="textarea"
                    placeholder="instructions about the excersice"
                    name="instructor"
                    onChange={handleInput}
                    style={{ height: '100px' }}
                  />
                </Form.Group>

                <Form.Group className="m-3" controlId="formBasicEmail">
                  <Button
                    style={{ width: '100%' }}
                    variant="primary"
                    size="lg"
                    type="submit">
                    {!isLoading ? (
                      'Submit'
                    ) : (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Form.Group>
                {/* show alert or success message here */}
                {showModal && (
                  <Alert variant="success">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{message}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button
                        //onClick={() => closeSuccessModal()}
                        variant="outline-success">
                        Close
                      </Button>
                    </div>
                  </Alert>
                )}
                {isError && (
                  <Alert variant="danger">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{message}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={() => setIsError(false)}
                        variant="outline-danger">
                        Close
                      </Button>
                    </div>
                  </Alert>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default AddNew;
